import React from 'react';
import './TermsAndConditions.css';

export const BIRBLI_FEE_RATE = "23%";
export const BIRBLI_FEE_MIN_DOLLAR_AMOUNT = "$1.85";

export const TermsAndConditions = () => {
    return (
        <div className='container bg-transparent'>
            <h1 className='text-center fw-bold'>Terms and Conditions</h1>
            <p className='text-center'>Last Revision: December 18, 2024</p>

            <p className='mb-3'>
                By visiting or using Birbli (this website and the application), you are bound by these terms and conditions and Birbli's Privacy Policy. 'You' refers to any user or organization utilizing Birbli in any way, shape, or form.
            </p>

            <section className='mb-3'>
                <h2 className='fw-bold'>1. Service</h2>
                <p className='mb-3'>
                    The Birbli application is an end-to-end encrypted messaging platform that allows users to sell their time through text. Birbli's messaging platform is free to use. Users who wish to sell their time can choose their availability, set their hourly rate, and manage their interactions through the platform.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Availability and Rates:</strong> Sellers can set their availability and hourly rate. They are responsible for accepting or rejecting requests to communicate, and it is the user's responsibility to vet the requesting user.</li>
                    <li className='terms-list-item'><strong>Reservations:</strong> A reservation represents a period of time during which a buyer and seller have agreed to communicate through Birbli. Users can cancel a reservation anytime prior to payment. Reservations are prorated based on the seller's hourly rate.</li>
                    <li className='terms-list-item'><strong>Platform Fee:</strong> {
                        `Birbli will take a processing fee for each reservation. This fee will be either ${BIRBLI_FEE_MIN_DOLLAR_AMOUNT} or ${BIRBLI_FEE_RATE} of the pro-rated reservation cost, whichever is more.`
                    }
                    </li>
                    <li className='terms-list-item'><strong>Payouts:</strong> Sellers are paid out through Stripe on a weekly basis and are bound by Stripe's terms and conditions. Sellers must connect their banking platform to Stripe in order to receive payouts.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>2. User Generated Content</h2>
                <p className='mb-3'>
                    Birbli only allows you to share user-generated content through direct contacts, group chats, or within the context of a reservation. You cannot freely share content with anyone on the app outside of these parameters.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Ownership and Access:</strong> Content shared through Birbli belongs to the user. Birbli cannot access any messages or media shared through encrypted messages. However, public profile media is accessible but will not be used by Birbli for any purpose.</li>
                    <li className='terms-list-item'>Birbli reserves the right to limit what user-generated content is allowed on the app, and these limits may change without notice. Forbidden content consists of, but is not limited to:</li>
                    <ul className='terms-sublist'>
                        <li className='terms-list-item'>Hate Speech</li>
                        <li className='terms-list-item'>Gore</li>
                        <li className='terms-list-item'>Defamation</li>
                        <li className='terms-list-item'>Copyrighted Content</li>
                        <li className='terms-list-item'>Pornography</li>
                    </ul>
                    <li className='terms-list-item'>Uploading any of the above content or any other content that Birbli deems inappropriate will result in a ban from using any Birbli services, and authorities may be involved if necessary.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>3. Refunds and Resale</h2>
                <p className='mb-3'>
                    Refunds are awarded on a case-by-case basis. Users can report a conversation to end it and submit all pertinent details to Birbli for review. Birbli may then decide in favor of the user and provide a refund if applicable.
                </p>
                <ul className='terms-list'>
                    <li className='terms-list-item'><strong>Reporting:</strong> Reporting a paid conversation can be done through the app. Birbli will review the report and determine if a refund is warranted.</li>
                    <li className='terms-list-item'>Resale of Birbli accounts or services is strictly forbidden.</li>
                </ul>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>4. Warranty</h2>
                <p className='mb-3'>
                    Birbli is provided 'as-is' and does not come with a warranty of any kind.
                </p>
            </section>

            <section className='mb-3'>
                <h2 className='fw-bold'>5. Limitation of Liability</h2>
                <p className='mb-3'>
                    In no event will Birbli or its affiliates be liable for any damages arising from the use or inability to use Birbli.
                </p>
            </section>
        </div>
    );
};
