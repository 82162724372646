import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';

// TODO - This should be passed in as an ENV variable
const httpLink = new HttpLink({
    uri: 'https://birbli-user-service-us-central1-ed02e17-1064589084597.us-central1.run.app/graphql',
    headers: {
        'Content-Type': 'application/json',
    },
});

const authLink = new ApolloLink((operation, forward) => {
    return forward(operation);
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
