import './Home.scss';
import birb from '../assets/birb.png';
import encryptedImage from '../assets/encrypted-photo.png';
import cardinal from '../assets/cardinal.png';
import bookingMock from '../assets/booking_mock.png';
import birbOnPhone from '../assets/birbli_phone_medium.png';
import homeMock from '../assets/home_mock.png';
import secureMessagingImage from '../assets/Home.png';
import groupChatImage from '../assets/Groups.png';
import profileImage from '../assets/Profile.png';
import reserveImage from '../assets/Reservations.png';
import discoveryImage from '../assets/Discovery.png';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { midBreakPoint } from '../shared/breakpoints';

const carouselSettings = {
    adaptiveHeight: true,
    infinite: true,
    speed: 500,
    className: 'slider variable-width',
    draggable: true,
    arrows: false,
    fots: false,
    focusOnSelect: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 10000,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: midBreakPoint,
          settings: {
            slidesToShow: 1,
          }
        }
      ],
}

export const Home = () => {
    return (
        <>
            <div className='bg-transparent'>
                <div className='container'>
                    <div className='hero'>
                        <div className='d-flex text-container align-items-center'>
                            <div className='text-container'>
                                <h2 className='jumbo-text fw-bold'>
                                    Text Securely.<br/>
                                    Earn Effortlessly.<br/>
                                    Connect Meaningfully.<br/><br/>
                                    <span>
                                        <a className="google-play-link" href='https://play.google.com/store/apps/details?id=com.birbli&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                            <img className="google-play-badge-top" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                                        </a>
                                    </span>
                                </h2>
                            </div>
                        </div>
                        <div className='image-container'>
                            <img src={birb} className='birb d-none d-md-block' alt='birb'/>
                            <div className='mock-images'>
                                <img src={bookingMock} className='mock' alt='booking mock'/>
                                <img src={homeMock} className='mock' alt='home mock'/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: 'rgb(211, 222, 255)' }}>
                <div className='container'>
                    <h1 className='text-center pt-5 fw-bold'>Why Birbli?</h1>
                    <div className='d-flex mt-5 pb-5 row'>
                        <div className='col-md-4 order-1 p-3' style={{ animation: '1s ease-out 0s 1 slideInFromLeft' }}>
                            <h2 className='fw-bold'>Send Secure Messages</h2>
                            <p className='fs-4'>Every message on Birbli is end-to-end encrypted, so only you and the recipient can read it. Privacy is core to what we do—your conversations stay yours.</p>
                        </div>
                        <div className='col-md-6 order-md-1 bg-white offset-md-2' style={{ borderRadius: '30px' }}>
                            <div className='container p-4'>
                                <div className='row position-relative'>
                                    <img src={encryptedImage} alt='encrypted-cardinal' style={{  zIndex: 3, position: 'absolute', animation: '2s ease-out 1s 1 fadeOut forwards' }} className='p-1 encrypted-photo'/>
                                    <img src={cardinal} alt='cardinal' style={{ zIndex: 1, position: 'relative' }} className='p-1 encrypted-photo'/>
                                </div>
                                <div className='row p-4' style={{ float: 'right', width: '200px' }}>
                                    <p className='fs-4 p-2 text-center' style={{ borderRadius: '90px', backgroundColor: '#C3EEEDFF' }}>Cool photo!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-transparent'>
                <div className='container'>
                    <div className='d-flex mt-5 pb-5 row'>
                        <div className='col-md-6 d-flex p-3'>
                            <img src={discoveryImage} style={{ borderRadius: '25px', margin: 'auto', border: '2px solid gray', maxWidth: '400px', minWidth: '280px' }} alt='public profile'/>
                        </div>
                        <div className='col-md-4 p-3' style={{ animation: '1s ease-out 0s 1 slideInFromRight' }}>
                            <h2 className='fw-bold'>Discover Opportunities</h2>
                            <p className='fs-4'>Join a community waiting to connect. Book time with your favorite influencers. Sell your time and gain a following.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ backgroundColor: '#b4ddcf' }}>
                <div className='container'>
                    <div className='d-flex mt-5 pb-3 pt-5 row'>
                        <div className='p-3 text-center' style={{ animation: '1s ease-out 0s 1 slideInFromTop' }}>
                            <Slider {...carouselSettings}>
                                <div>
                                    <h3 className='fw-bold fs-4'>Send Secure Messages</h3>
                                    <img src={secureMessagingImage} style={{ maxWidth: '300px', maxHeight: '600px', margin: 'auto' }} alt='secure messaging'/>
                                </div>
                                <div>
                                    <h3 className='fw-bold fs-4'>Group Chats</h3>
                                    <img src={groupChatImage} style={{ maxWidth: '300px', maxHeight: '600px', margin: 'auto' }} alt='group chats'/>
                                </div>
                                <div>
                                    <h3 className='fw-bold fs-4'>Earn on your Terms</h3>
                                    <img src={profileImage} style={{ maxWidth: '300px', maxHeight: '600px', margin: 'auto' }} alt='discover'/>
                                </div>
                                <div>
                                    <h3 className='fw-bold fs-4'>Book and Reserve</h3>
                                    <img src={reserveImage} style={{ maxWidth: '300px', maxHeight: '600px', margin: 'auto' }} alt='search'/>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>

            <div className='bg-transparent'>
                <div className='container'>
                    <div className='d-flex mt-5 pb-5 row'>
                        <img src={birbOnPhone} style={{ width: '215px', height: '200px', margin: 'auto' }} alt='birb on phone'/>
                        <span className='text-center'>
                            <a className="google-play-link" href='https://play.google.com/store/apps/details?id=com.birbli&hl=en&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                                <img className="google-play-badge" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                            </a>
                        </span>
                        <span className='text-center'>
                            <Link to='/' className='btn fs-3' style={{backgroundColor: '#C3EEEDFF' }}>Coming soon to iOS</Link>
                        </span>
                    </div>
                </div>
            </div>
        </>
    );
};
